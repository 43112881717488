import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import BannerHeader from '../components/BannerHeader';
import Layout from '../components/Layout';
import LearnHero from '../components/LearnHero';
import ReviewBar from '../components/ReviewBar';
import Testimonials from '../components/Testimonials';
import SEO from '../components/SEO';

export const TestimonialsPageTemplate = ({ header, testimonials }) => (
  <div>
    <SEO title="Testimonials" pathname="/testimonials" />
    <BannerHeader
      title={header.title}
      subtitle={header.subtitle}
      image={header.image}
    />
    <section className="section">
      <div className="container content markdown-content">
        <Testimonials testimonials={testimonials} />
      </div>
    </section>
  </div>
);

TestimonialsPageTemplate.propTypes = {
  header: PropTypes.shape({
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    title: PropTypes.string,
    subtitle: PropTypes.string
  }),
  tesimonials: PropTypes.shape({
    quote: PropTypes.string,
    author: PropTypes.string
  })
};

const TestimonialsPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <TestimonialsPageTemplate
        header={frontmatter.header}
        testimonials={frontmatter.testimonials}
      />
      <LearnHero />
      <ReviewBar />
    </Layout>
  );
};

TestimonialsPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object
    })
  })
};

export default TestimonialsPage;

export const pageQuery = graphql`
  query TestimonialsPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "testimonials-page" } }) {
      frontmatter {
        header {
          title
          subtitle
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        testimonials {
          quote
          author
        }
      }
    }
  }
`;
